<template>
  <div class="add-form">
    <el-form ref="ruleFormRef" :rules="kpiRule" label-position="left" :model="ruleForm" label-width="120px"
      class="demo-ruleForm" label-suffix="：">
      <el-form-item prop="name" label="考核表名称">
        <el-input v-model="ruleForm.name" placeholder="请输入" />
      </el-form-item>

      <el-form-item prop="cycleType" label="周期类型">
        <el-select v-model="ruleForm.cycleType" class="m-2" placeholder="请选择">
          <el-option v-for="item in cycleTypeList" :key="item.id" :label="item.label" :value="item.id" />
        </el-select>
      </el-form-item>

      <el-form-item prop="roleId" label="被考核人">
        <el-select v-model="ruleForm.roleId" class="m-2" placeholder="请选择" @change="handleRoleChange">
          <el-option v-for="item in roleTypeList" :key="item.roleId" :label="item.roleName" :value="item.roleId" />
        </el-select>
      </el-form-item>
      <el-form-item label="考核指标" prop="itemList">
        <el-cascader v-model="ruleForm.itemList" :options="options" :props="props" clearable />
      </el-form-item>
      <!-- <el-form-item
        v-for="(items, index) in ruleForm.itemList"
        :key="index"
        :label="index==0?'考核指标':''"
      >
        <div style="display:flex;align-items: center;margin-bottom:10px;">
          <el-form-item :prop="'itemList.' + index + '.type'" :rules="{
              required: true,
              message: 'item can not be null',
              trigger: 'blur',
            }">
            <el-select :disabled="!ruleForm.roleId" v-model="items.type" class="m-2" placeholder="请选择指标类型" style="margin-right:10px;" @change="handleTypeChange($event, index)">
              <el-option v-for="item in typeList" :key="item.id" :label="item.label" :value="item.id" />
            </el-select>
          </el-form-item>
          <el-button type="danger" v-if="index!=0" size="small" icon="Delete" circle @click.prevent="removeDomain(index)" />
          <el-button type="primary" v-else  size="small" icon="Plus" circle :disabled="ruleForm.itemList.length>2" @click.prevent="addDomain"/>
        </div>
        <el-form-item :prop="'itemList.' + index + '.indicatorsId'" :rules="{
            required: true,
            message: 'item can not be null',
            trigger: 'blur',
          }">
          <el-select :disabled="!items.type" v-model="items.indicatorsId" class="m-2" placeholder="请选择指标名称">
            <el-option v-for="item in items.nameList" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>
      </el-form-item> -->
      <el-form-item style="margin-top:40px" v-if="!$route.query.isCheck">
        <el-button @click="saveTargetClick(ruleFormRef)" type="primary">提交</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router'
import { getCheckIndicatorsDownList,createCheckTable,getCheckTable,editCheckTable } from '@/service/main/target'
import { COMPANY_ID } from '@/common/constant'
import { ElMessage } from 'element-plus'
import { kpiRule} from '../../../../config'
import dayjs from 'dayjs'
import {getAllRoleList} from '@/service/main/staff'

export default defineComponent({
  props: {

  },
  components: {
  },
  setup() {
    const ruleFormRef = ref()
    const route = useRoute()
    const router = useRouter()
    const cycleTypeList = reactive(
      [{id:1,label:'月度'}]
    )
    const typeList = reactive(
      [{id:1,label:'量化指标'},{id:2,label:'加分项'},{id:3,label:'扣分项'}]
    )
    const roleTypeList = ref([])

    const props = { multiple: true,emitPath:false,label:'name',value:'id',children:'indicatorsDownListVoList' }
    const ruleForm = reactive({
      itemList: [],
      roleId:'',
      cycleType:'',
      name:'',
    })

    // const addDomain = () => {
    //   ruleForm.itemList.push({
    //     type:null,
    //     indicatorsId:null,
    //     nameList:[]
    //   })
    // }
    // const removeDomain = (index) => {
    //   ruleForm.itemList.splice(index, 1)
    // }
    // const handleTypeChange = async(e,index)=>{
    //   ruleForm.itemList[index].indicatorsId=''
    //   let res = await getCheckIndicatorsDownList({roleId:ruleForm.roleId,type:e})
    //   ruleForm.itemList[index].nameList = res.data
    // }
    const handleRoleChange = async(e)=>{
      ruleForm.itemList=[]
      let res = await getCheckIndicatorsDownList({roleId:e})
      res.data.map(item=>{
        if (item.indicatorsDownListVoList&&item.indicatorsDownListVoList.length<=0) {
          item.disabled=true
        }
      })
      options.value = res.data
    }
    const options = ref([])

    const getData = async() => {
      let res = await getCheckTable(route.query.id)
      handleRoleChange(res.data.roleId)
      let array = res.data.itemList.map(item=>{
        return item.indicatorsId
      })
      ruleForm.roleId = res.data.roleId
      ruleForm.cycleType = res.data.cycleType
      ruleForm.itemList = array
      ruleForm.name = res.data.name
    }

    const saveTargetClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          let array = ruleForm.itemList.map(item=>{
            return {indicatorsId:item}
          })
          let params = {
            itemList: array,
            roleId:ruleForm.roleId,
            cycleType:ruleForm.cycleType,
            name:ruleForm.name,
          }
          if (route.query.id) {
            let res = await editCheckTable({...params,id:route.query.id})
            if (res.code == 0) {
              ElMessage({
                message: '编辑成功!',
                type: 'success',
              })
              router.back()
            } else {
              ElMessage.error('编辑失败，请重试!')
            }
          }else{
            let res = await createCheckTable(params)
            if (res.code == 0) {
              ElMessage({
                message: '添加成功!',
                type: 'success',
              })
              router.back()
            } else {
              ElMessage.error('添加失败，请重试!')
            }
          }
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const initPage = async () => {
      if (!route.query.id) return;
      getData()
    }
    initPage()
    const getRoleList = async() =>{
      const roleRes = await getAllRoleList()
      roleTypeList.value = roleRes.data
    }
    getRoleList()



    return {
      COMPANY_ID,
      ruleFormRef,
      ruleForm,
      saveTargetClick,
      cycleTypeList,
      typeList,
      roleTypeList,
      // addDomain,
      // removeDomain,
      // handleTypeChange,
      handleRoleChange,
      options,
      props,
      kpiRule,
    }

  }
})
</script>

<style scoped lang="less">
.add-form {
  .opt-staff {
    &:deep(.el-tag) {
      margin-right: 10px;
    }
  }

  &:deep(.el-input) {
    width: 250px;
  }
}
</style>




