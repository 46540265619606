<template>
  <div class="add-category-target">
    <div v-if="$route.query.type == 0">
      <breadcrumb v-if="$route.query.id" :breadcrumbList="edittagetBreadcrumbList"></breadcrumb>
      <breadcrumb v-else :breadcrumbList="addtagetBreadcrumbList"></breadcrumb>
      <div class="add-yarget-content">
        <addForm></addForm>
      </div>
    </div>
    <div v-if="$route.query.type == 3">
      <breadcrumb v-if="$route.query.id" :breadcrumbList="kpiDeatil"></breadcrumb>
      <breadcrumb v-else :breadcrumbList="addKpi"></breadcrumb>
      <div class="add-yarget-content">
        <addFormKpi></addFormKpi>
      </div>
    </div>
    
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import Breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { addtagetBreadcrumbList,edittagetBreadcrumbList,addKpi,kpiDeatil } from '../../config'
import addForm from './cpns/add-form/add-form.vue'
import addFormKpi from './cpns/add-kpi/add-form.vue'

export default defineComponent({
  props: {
   
  },
  components: {
    Breadcrumb,
    addForm,
    addFormKpi
  },
  setup() {


    return {
      addtagetBreadcrumbList,
      edittagetBreadcrumbList,
      addKpi,
      kpiDeatil
    }

  }
})
</script>

<style scoped lang="less">
.add-category-target {
  min-width: 980px;

  .add-yarget-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
  }
}
</style>




